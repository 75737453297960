<template>
  <FormModal title="Condiciones de empaquetado" show-back-btn>
    <template #body>
      <PackingConditionsAssemblyDateFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import PackingConditionsAssemblyDateFormStep from '~/components/form/steps/packingConditionsAssemblyDate/PackingConditionsAssemblyDateFormStep'

export default {
  components: {
    PackingConditionsAssemblyDateFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Condiciones de la mudanza',
      headDescription: 'Proporcione los detalles de la mudanza.',
      path: '/solicitudes-de-cotizacion/condiciones-de-la-mudanza',
      progressValue: 80,
    }
  },

  /**
   */
  beforeMount() {
    this.setCheckoutStep(4)
  },
}
</script>
