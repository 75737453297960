<template>
  <div>
    <t-form ref="form" @submit.prevent>
      <MovePackingConditionsFormPart @result="onResult" @change="onChange" />

      <h2 class="form-modal__part-title">(Des)Montaje</h2>

      <MoveDeAssemblyWorkFormPart @result="onResult" @change="onChange" />

      <h2 class="form-modal__part-title">Fecha deseada</h2>

      <MovePreferredDateFormPart @result="onResult" @change="onChange" />
    </t-form>

    <FormErrorMessages :error-messages="errorMessages" />
  </div>
</template>

<script>
import FormErrorMessages from 'chimera/all/components/form/FormErrorMessages'
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import MovePackingConditionsFormPart from 'chimera/moving/components/form/parts/movePackingConditions/MovePackingConditionsFormPart'
import MoveDeAssemblyWorkFormPart from 'chimera/moving/components/form/parts/moveDeAssemblyWork/MoveDeAssemblyWorkFormPart'
import MovePreferredDateFormPart from 'chimera/moving/components/form/parts/movePreferredDate/MovePreferredDateFormPart'
import NextStep from '~/pages/solicitudes-de-cotizacion/introduce-tus-datos'

export default {
  name: 'PackingConditionsAssemblyDateFormStep',

  components: {
    MovePackingConditionsFormPart,
    MoveDeAssemblyWorkFormPart,
    MovePreferredDateFormPart,
    FormErrorMessages,
  },

  extends: AbstractFormStep,

  data: () => ({
    willTransitionOnValid: true,
  }),

  /**
   * Pass through the submit event given from FormModal
   */
  created() {
    this.$nuxt.$on('submit', this.onSubmit)
  },

  /**
   * Clean up before component is destroyed
   */
  destroyed() {
    this.$nuxt.$off('submit')
  },

  methods: {
    /**
     * Transition
     */
    transition() {
      this.routeTo(NextStep)
    },
  },
}
</script>
